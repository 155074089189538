import React from "react";
import FlipClockCountdown from "@leenguyen/react-flip-clock-countdown";
import "@leenguyen/react-flip-clock-countdown/dist/index.css";
import "./styles.css";
import videoBg from "../src/assets/video.mp4";

const App = () => {
  const email = "opgenhoff@gryfe.dev";
  function onEmailClick() {
    window.open(`mailto:${email}`);
  }

  const tmp = new Date("2023-05-31");
  return (
    <section className="page">
      <div
        dangerouslySetInnerHTML={{
          __html: `
      <video 
          loop
          muted
          autoplay
          playsinline
          preload="metadata" >
        <source src="${videoBg}" type="video/mp4" />
      </video>
      `,
        }}
      ></div>
      <div className="page__content">
        <h1>Launching Soon</h1>
        <h3>Developing Anywhere. Anytime. </h3>
        <FlipClockCountdown
          className="flip-clock"
          to={tmp.getTime() + 24 * 3600 * 1000 + 5000}
          labels={["DAYS", "HOURS", "MINUTES", "SECONDS"]}
        />
        <button className="btn" onClick={onEmailClick}>
          Get In Touch
        </button>
      </div>
    </section>
  );
};

export default App;
